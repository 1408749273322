<template>
  <div>
    <div
        class="token-card"
    >
      <img
          :src="`https://royal-io.imgix.net/${ArtworkImg}`"
          alt="edition-card-img"
      />
      <div
          class="token-rating"
          :class="{'drop-rating-diamond': tier === 'DIAMOND', 'drop-rating-platinum' : tier === 'PLATINUM', 'drop-rating-gold' : tier === 'GOLD'}">
        <span>{{ Tier }}</span>
        <span>{{ EditionType }}</span>
      </div>
      <div class="token-infos">
        <p>{{ RoyaltyClaimMillionths }} </p>
        <p>OWNERSHIP PER TOKEN</p>
        <hr/>
        <p>{{ Price }}</p>
        <p>
          <v-icon size="20">mdi-message-processing-outline</v-icon>
          <span>Exclusive access to Ollie NFT Collector Discord channel</span>
        </p>

        <v-dialog
            v-model="dialog"
            width="340"

        >
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs"
                    v-on="on">READ MORE
            </button>
          </template>

          <v-card class="tier-modal">
            <v-card-title>
              PLATINUM TIER
            </v-card-title>

            <v-card-text>
              Access to Ollie NFT Collector Discord channel is transferable with token verification.
            </v-card-text>
            <v-card-actions>
              <v-btn
                  dark
                  @click="dialog = false"
              >
                OK GOT IT
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <button>SOLD OUT</button>
      </div>
    </div>
  </div>
</template>
<script>
import {reactive, toRefs, computed} from '@vue/composition-api'

export default {
  components: {},
  props: {
    artworkImg: {type: String, default: ''},
    tier: {type: String, default: ''},
    editionType: {type: String, default: ''},
    royaltyClaimMillionths: {type: Number, default: 0},
    price: {type: String, default: ''},
    tokenCount: {type: String, default: ''},
    saleCount: {type: String, default: ''},
  },
  setup(props) {
    const state = reactive({
      ArtworkImg: props.artworkImg,
      Tier: props.tier,
      EditionType: props.editionType,
      RoyaltyClaimMillionths: computed(() => `${props.royaltyClaimMillionths/10000}%`),
      Price: computed(() => `$${Number(props.price).toLocaleString('en')}`),
      TokenCount: props.tokenCount,
      SaleCount: props.saleCount
    })
    return toRefs(state)
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    goClick() {
      this.$emit('click')
    },
  },
}
</script>
