<template>
  <div class="edition">
    <!--  edition info section-->
    <section class="edition-info-section">
      <div v-show="$store.state.app.isMobile">
        <div class="sns-share">
          <v-menu offset-y dark>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-bind="attrs"
                  v-on="on">mdi-share
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <p class="edition-info-subtitle">{{ editionInfo.rightsAsset.type }}</p>
        <p class="edition-info-title">{{ editionInfo.rightsAsset.title }}</p>
      </div>
      <div class="edition-album">
        <video muted autoplay loop>
          <source src="@/assets/videos/Network.mp4" type="video/mp4">
          <strong>Your browser does not support the video tag.</strong>
        </video>
        <div class="album-info">
          <div class="artist-profile" @click="goPage('artist')">
            <v-avatar size="40">
              <img
                  :src="`https://royal-io.imgix.net/${editionInfo.contributors[0].profile.avatarAsset.filename}`"
                  alt="artist-avatar"
              >
            </v-avatar>
            <div class="artist-info">
              <p>{{ editionInfo.contributors[0].profile.name }}</p>
              <p>Arist</p>
            </div>
          </div>
          <img
              :src="`https://royal-io.imgix.net/${editionInfo.rightsAsset.heroAsset.filename}`"
              alt="album-cover"/>
        </div>
      </div>
      <div class="edition-info">
        <div class="edition-info-text">
          <div class="sns-share">
            <v-menu offset-y dark>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-show="!$store.state.app.isMobile"
                    v-bind="attrs"
                    v-on="on">mdi-share
                </v-icon>
              </template>
              <v-list>
                <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div v-show="!$store.state.app.isMobile">
            <p class="edition-info-subtitle">{{ editionInfo.rightsAsset.type }}</p>
            <p class="edition-info-title">{{ editionInfo.rightsAsset.title }}</p>
          </div>
          <p v-show="$store.state.app.isMobile" class="edition-info-subtitle">EP</p>
          <p class="edition-info-description">{{ editionInfo.description }}</p>
          <a class="view-collection">View Collection
            <v-icon small>mdi-arrow-right</v-icon>
          </a>
        </div>
        <div class="edition-player">
          <iframe :src=editionInfo.rightsAsset.externalIdentifiers[0].externalUrl width="100%" height="200"
                  allow="encrypted-media" title="Underrated"></iframe>
        </div>
      </div>
    </section>

    <!--    tokens section-->
    <section class="tokens-section">
      <p>TOKENS</p>
      <p>Each token represents streaming royalty ownership in the full EP - all four songs.</p>

      <div class="token-cards">
        <div v-for="(token, i) in editionInfo.tiers" :key="i">
          <card-edition
              :artworkImg=token.artworkAsset.filename
              :tier=token.type
              :editionType=token.edition.rightsAsset.type
              :royaltyClaimMillionths=token.royaltyClaimMillionths
              :price=token.tierSale.saleConfig.price
          />

          <p class="tokens-left">{{ token.tokenCount - token.tierSale.saleCount }}/{{ token.tokenCount }} TOKENS
            LEFT</p>
        </div>
      </div>
    </section>

    <!--    breakdown section-->
    <section class="breakdown-section">
      <p>A BREAKDOWN</p>
      <div class="owner-infos">
        <div class="owner-info">
          <p>TOTAL TOKENS</p>
          <p>{{ totalTokens }}</p>
          <p>3 TIERS</p>
        </div>
        <div class="owner-info">
          <p>TOTAL OWNERSHIP OFFERED</p>
          <p>{{ totalOwnershipOffered }}</p>
          <p>STREAMING ROYALTY</p>
        </div>
        <div class="owner-info">
          <p>AMOUNT RAISED</p>
          <p>{{ amountRaised }}</p>
          <p>USD</p>
        </div>
      </div>
      <div class="pie-chart">
        <apexchart width="500" type="pie" :options="options" :series="series"></apexchart>
      </div>
    </section>

    <!--    about section-->
    <section class="about-section">
      <p>ABOUT</p>
      <div class="about">
        <table>
          <tr>
            <td>Edition
            </td>
            <td>Blockchain</td>
            <td>Contract Address

            </td>
            <td>Legal</td>
          </tr>
          <tr>
            <td>{{ editionInfo.rightsAsset.title }}</td>
            <td>{{ editionInfo.tiers[0].chainContract.network.name }}</td>
            <td>
              <a :href="`https://polygonscan.com/address/${editionInfo.tiers[0].chainContract.address}`"
                 target="_blank">
                {{ contractAddress }}
                <v-icon size="15">mdi-arrow-right-thick</v-icon>
              </a>
            </td>
            <td>
              Read Now
              <v-icon size="15">mdi-arrow-right-thick</v-icon>
            </td>
          </tr>
        </table>
        <div class="about-description">
          Tokens sold by artists on royal have embedded streaming royalty rights. The percentage varies depending on the
          token tier. To claim streaming royalties after they've accrued, the token must be in your wallet.
        </div>
      </div>
    </section>

    <!--    collection section-->
    <section class="collection-section">
      <div class="collection-top">
        <p>COLLECTORS</p>
        <div class="drop-list">
          <v-select
              v-model="select"
              :items="dropList"
              label="ALL TIERS"
              append-icon="mdi-menu-down"
              dense
              solo
              @change="onChange(select)"
          ></v-select>
        </div>
      </div>

      <div
          class="collectors"
          v-for="(collector, i) in collectorLoaded" :key="i">
        <div class="collector-profile">
          <v-avatar size="40" @click="goPage('user-profile')">
            <img
                :src="!collector.node.wallet.user ? 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ5ynbgJy2hzqlN4PKwb6-te9VvZ0yTx3sVQ&usqp=CAU'
          : !collector.node.wallet.user.profiles[0].avatarAsset ? 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ5ynbgJy2hzqlN4PKwb6-te9VvZ0yTx3sVQ&usqp=CAU' : `https://royal-io.imgix.net/${collector.node.wallet.user.profiles[0].avatarAsset.filename}`"
                alt="collector-avatar"/>
          </v-avatar>
          <div class="collector-info">
            <p @click="goPage('user-profile')">
              {{ collector.node.wallet.user ? `@${collector.node.wallet.user.profiles[0].slug}` : '' }}</p>
            <p @click="makeToast(), copyAddress(collector.node.wallet.address)">{{ reduceAddress(collector.node.wallet.address) }}</p>
          </div>
        </div>
        <div class="collector-status">
          <p><span v-if=" collector.node.tier.type === 'DIAMOND'">💎</span>{{ collector.node.tier.type }}</p>
          <p>1/{{ collector.node.tier.maxSupply }}</p>
        </div>
      </div>
      <div class="see-more" v-if="length < collectors.length">
        <a @click="loadMore">See MORE</a>
      </div>
    </section>

    <!--    how it works section-->
    <section class="how-it-works-section">
      <p>ROYAL // HOW IT WORKS</p>
      <div class="how-it-works">
        <div class="how-it-work" v-for="(work,i) in howITWorks.features" :key="i">
          <div class="how-it-work-img">
            <img
                :src=work.image.url
                alt="how-it-works"/>
          </div>
          <div class="how-it-work-text">
            <p>
              {{ work.title }}
            </p>
            <p>
              {{ work.description }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <!--  edition featured section-->
    <section class="edition-featured-section">
      <p>FEATURED</p>
      <banner
          skin="dashboard-featured-img"
          :bannerTitle="featuredEdition.name"
          :bannerType="featuredEdition.type"
          :bannerImg="`https://royal-io.imgix.net/${featuredEdition.filename}`"
          @click="goPage('edition')"/>
    </section>
  </div>
</template>

<script>
import CardEdition from "@/components/Card/CardEdition";
import Banner from "@/components/Banner/Banner";
import { POSITION } from "vue-toastification";

export default {
  name: 'Edition',
  components: {
    CardEdition,
    Banner,
  },
  data() {
    return {
      editionInfo: [],
      collectors: [],
      length: 15,
      dropList: ["ALL TIERS", "GOLD", "PLATINUM", "DIAMOND"],
      select: '',
      resetCollectors: [],
      tierList: [
        {text: "AlL TIERS", value: 0},
        {text: "GOLD", value: 1},
        {text: "PLATINUM", value: 2},
        {text: "DIAMOND", value: 3}],
      selected: "0",
      series: [],
      options: {
        colors: ['#B1E3E8', '#CFD8E2', '#E8CD99', '#2E2BC4'],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false
        },
        labels: [],
        tooltip: {
          enabled: true,
          custom: function (opts) {
            let data = opts.ctx.w.globals.labels[0]
            let title = ['DIAMOND', 'PLATINUM', 'GOLD', data.data.edition.contributors[0].profile.name]
            let tokenCount = [`${data.data.edition.tiers[2].maxSupply} Tokens /`, `${data.data.edition.tiers[1].maxSupply} Tokens /`, `${data.data.edition.tiers[0].maxSupply} Tokens /`, '']
            let royaltyRightsPercentage = [` ${data.data.edition.tiers[2].royaltyRightsPercentage}% Royalties`, ` ${data.data.edition.tiers[1].royaltyRightsPercentage}% Royalties`, `${data.data.edition.tiers[0].royaltyRightsPercentage}% Royalties`, ` ${data.data.edition.contributors[0].royaltyRightsPercentage}% Royalties`]
            // TODO
            // let goldOwner = data.tokens.edges.filter(e => e.node.tier.type = 'GOLD')
            // let platinumOwner = data.tokens.edges.filter(e => e.node.tier.type = 'PLATINUM')
            // let diamondOwner = data.tokens.edges.filter(e => e.node.tier.type = 'DIAMOND')
            // let owners = [goldOwner, platinumOwner, diamondOwner, '']
            // const img = document.querySelector('img');
            // img.src = "https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg"
            return (
                '<div class="tooltip">' +
                '<p>' + title[opts.seriesIndex] + '</p>' +
                '<div class="tooltip-content">' +
                '<span>' + tokenCount[opts.seriesIndex] + '</span>' +
                '<span>' + royaltyRightsPercentage[opts.seriesIndex] + '</span>' +
                '</div>' +
                '</div>'
            )
          },
        }
      },
      items: [
        {title: 'Twitter'},
        {title: 'Facebook'},
        {title: 'Copy Link'},
      ],
      dialog: false,
      howITWorks: {},
      featuredEdition: {}
    }
  },
  computed: {
    totalTokens() {
      return this.editionInfo.breakdown.totalTokens.toLocaleString('en')
    },
    totalOwnershipOffered() {
      return `${100 - this.editionInfo.contributors[0].royaltyRightsPercentage}%`
    },
    amountRaised() {
      return `${this.splitByUnit(this.editionInfo.breakdown.amountRaised)}`
    },
    contractAddress() {
      return this.reduceAddress(this.editionInfo.tiers[0].chainContract.address)
    },
    collectorLoaded() {
      return this.collectors.slice(0, this.length)
    }
  },
  async mounted() {
    await this.editionData()
  },
  methods: {
    async editionData() {
      await this.$http.get(`/edition/${this.$route.params.permalink}`
      ).then(res => {
        this.editionInfo = res.data.data.edition
        this.collectors = res.data.tokens.edges
        this.resetCollectors = res.data.tokens.edges
        this.series = [res.data.data.edition.tiers[2].royaltyRightsPercentage, res.data.data.edition.tiers[1].royaltyRightsPercentage, res.data.data.edition.tiers[0].royaltyRightsPercentage, res.data.data.edition.contributors[0].royaltyRightsPercentage]
        this.options.labels = [res.data]
        this.howITWorks = res.data.info
        this.featuredEdition = res.data.featuredEdition
      }).catch
      ((err) => {
        console.log(err)
      })
    },
    goPage(name) {
      this.$router.push({
        name: name,
        params: {
          artist: this.editionInfo.contributors[0].profile.name,
          edition: this.featuredEdition.name
        }
      })
    },
    splitByUnit(number) {
      let inputNumber = number < 0 ? false : number;
      let unitWords = ['', 'k', 'M', 'B'];
      let splitUnit = 1000;
      let splitCount = unitWords.length;
      let resultString = '';

      for (let j = 0; j < splitCount + 1; j++) {
        if (inputNumber / Math.pow(splitUnit, j) < splitUnit && inputNumber / Math.pow(splitUnit, j) >= 1) {
          let unitResult = inputNumber / Math.pow(splitUnit, j + 1)
          resultString = String(unitResult.toFixed(3) * splitUnit) + unitWords[j];
        }
      }
      return resultString
    },
    reduceAddress(value) {
      return value.slice(0, 6) + '...' + value.slice(38, 42)
    },
    loadMore() {
      if (this.length < this.collectors.length)
        return this.length += 15
    },
    onChange(value) {
      this.collectors = this.resetCollectors
      if (value !== 'ALL TIERS') {
        let result = this.collectors.filter(collector => collector.node.tier.type === value)
        this.collectors = []
        this.collectors.push.apply(this.collectors, result)
      }
    },
    makeToast() {
      this.$toast("COPIED", {
        position: POSITION.BOTTOM_CENTER,
        toastClassName: "copy-toast-class",
        bodyClassName: "copy-class",
      })
    },
    copyAddress(address) {
      this.$copyText(address)
          .then(() => {
          })
          .catch(err => {
            console.log(err);
          })
    },
  }
}
</script>